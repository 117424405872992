<template>
    <b-overlay :show="isLoadingData">
        <b-card>
            <b-row align-v="center">
                <b-col>
                    <Filters
                        :perPage="perPage"
                        :perPageOptions="perPageOptions"
                        :searchQuery="searchQuery"
                        @change-per-page="changePerPage"
                        @search-in-table-records="searchInTableRecords"
                    />
                </b-col>
                <b-col cols="2" class="text-right">
                    <b-button
                        :to="{ name: 'create-hotel-performance'}"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                    >
                        <feather-icon icon="PlusIcon" style="color: white" />
                    </b-button>
                </b-col>
            </b-row>

            <b-table
                ref="refMembershipsListTable"
                :items="hotelPerformance"
                :fields="tableColumns"
                primary-key="id"
                show-empty
                responsive
                :filter="searchQuery"
                :filter-included-fields="['resortsName']"
                empty-text="No hay registros"
                class="position-relative mb-0"
            >

                <template #cell(status)="row">
                    <span class="text-nowrap">
                        <b-badge pill :variant="row.item.status ? 'success': 'danger'" >
                            {{ row.item.status ? "Activo" : "Desactivado" }}
                        </b-badge>
                    </span>
                </template>
                <template #cell(actions)="row">
                    <div>
                    <b-button
                        size="sm"
                        :to="{ name: 'edit-hotel-performance', params: { id: row.item.id } }"
                        variant="primary"
                    >
                        <feather-icon icon="Edit2Icon" size="12" />
                    </b-button>
                    </div>
                </template>
            </b-table>

            <PaginationTable
                :currentPage="currentPage"
                :totalRows="listHotelPerformance.length"
                :perPage="perPage"
                @change-page="changePage"
                v-if="listHotelPerformance.length"
            />
        </b-card>
    </b-overlay>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Filters from '@/modules/fivesClub/components/catalogs/ownersWebsite/Filters'
import PaginationTable from '@/modules/fivesClub/components/catalogs/ownersWebsite/PaginationTable'

export default {
    directives: { Ripple, },
    components: {
        vSelect,
    },
    async created(){
        await this.init();
    },
    components:{
        PaginationTable,
        Filters
    },
    data(){
        return {
            perPage: 10,
            currentPage: 1,
            perPageOptions: [5, 10, 15, 20, 25, 50, 100],
            searchQuery: '',
            sortBy: 'id',
            isSortDirDesc: true,

            isLoadingData: false,
            listHotelPerformance: [],
            filterHotelPerformance: null,
            tableColumns: [
                { key: 'resortsName', label: 'Hotel', class: 'text-center' },
                { key: 'urlgraphic', label: 'Imagen', class: 'text-center', formatter: value => {
                    return value.replace('media/images/hotelperformance/', '')
                }},
                { key: 'languageName', label: 'Lenguaje', class: 'text-center', formatter: value =>{
                    return value ? value : '--'
                }},
                { key: 'status', label: 'Status', class: 'text-center' },
                { key: 'actions', label: 'Acciones', class: 'text-center' },
            ],
        }
    } ,
    computed: {
        ...mapState('start', ["hotels"]),
        ...mapState("auth", ["user"]),
        hotelPerformance(){
            if (this.listHotelPerformance?.length) return this.listHotelPerformance.slice( (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage )
        }

    },
    methods: {
        ...mapActions('fivesClubCatalogs', ["getInitialContentForHotelPerformance", "updateNewsLetters"]),
        ...mapMutations('start',['setHotels']),

        async init(){
            this.isLoadingData = true
            const { hotels, hotelsPerformance } = await this.getInitialContentForHotelPerformance()
            this.listHotelPerformance = hotelsPerformance

            if( !this.hotels.length ){
                this.setHotels(hotels)
            }

            this.isLoadingData = false
        },

        changePage(page){
            this.currentPage = page
        },
        changePerPage(perPage){
            this.perPage = perPage
        },
        searchInTableRecords(string){
            this.searchQuery = string
        },
    },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
.head-btn{
display: flex;
justify-content: flex-end;
margin-bottom: 1rem;
}

.actions-status{
display: flex;
align-items: center;
gap: 1rem;
}
</style>
