<template>
  <b-row class="mb-1">
    <b-col md="2">
      <label>Numero de registros</label>
      <v-select
        v-model="porPagina"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="perPageOptions"
        :clearable="false"
        class="per-page-selector"
        @input="changePerPage"
      />
    </b-col>
    <b-col md="4">
      <label>Buscar en tabla</label>
      <div class="d-flex">
        <b-form-input
          v-model="search"
          class="mr-1"
          placeholder="Buscar en la tabla"
          type="search"
          @input="searchInRecords"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  props:{
    perPage:{
      type: Number,
      required: true,
    },
    perPageOptions:{
      type: Array,
      required: true,
    },
    searchQuery:{
      type: String,
      required: true,
    },

  },
  components: {
    vSelect,
  },
  data() {
    return {
      porPagina: this.perPage,
      statusFilter: null,
      statusOptions : ['Downloaded','Draft','Paid','Partial Payment','Past Due',],
      search: this.searchQuery
    }
  },
  methods:{
    changePerPage( perPage ){
      this.$emit('change-per-page', perPage)
      this.porPagina = perPage
    },
    searchInRecords( query ){
      this.$emit('search-in-table-records', query)
      this.search = query
    }
  }
}
</script>
<style>
.custom-margin{
  margin: 0.5rem;
}
</style>